import React from 'react';
import PropTypes from 'prop-types';
import Style from 'style-it';
import imageLibrary from '../assets/images';

const BackgroundImage = (image) => {
  const { standard, retina } = imageLibrary[image];

  return (
    <Style>
      {`.rbiHandler-${image} {
          background-image: url(${standard});
          background-image: -webkit-image-set(url(${standard}) 1x, url(${retina}) 2x);
          background-image: image-set(url(${standard}) 1x, url(${retina}) 2x);
        }
      `}
    </Style>
  );
};

const PrismicImage = (key, imageSet) => {
  const retina = imageSet.url;
  const standard = imageSet.standard.url;

  return (
    <Style>
      {
      `.rbiHandler-${key} {
        background-image: url(${standard});
        background-image: -webkit-image-set(url(${standard}) 1x, url(${retina}) 2x);
        background-image: image-set(url(${standard}) 1x, url(${retina}) 2x);
      }`
    }
    </Style>
  );
};

PrismicImage.defaultProps = {
  imageSet:[],
};

PrismicImage.propTypes = {
  imageSet: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string,
    standard: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

export { BackgroundImage, PrismicImage };
