import React from 'react';

const Input = (props) => {
  const {
    type,
    name,
    title,
    placeholder,
    value,
    handlechange,
    // style,
    ...rest
  } = props;

  return (
    <div>
      <label htmlFor={name}>{title}</label> {/* eslint-disable-line */}
      <input
        id={name}
        name={name}
        type={type}
        value={value}
        onChange={handlechange}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};

export default Input;
