import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { BackgroundImage, PrismicImage } from '../../utils';
import SubscribeForm from './Subscribe';
import Sdks from '../Sdks';
import ActionButtons from '../ActionButtons';

const SubscribeLarge = (props) => {
  const {
    title, titleColor, titleSize, textColor, children, background,
    tags, actionMessage, showSdkLinks, showSubscribeForm, actions, flag,
    reverse, prismicId, image, imageSet, ...rest
  } = props;

  const titleClasses = cx('title', titleColor, titleSize);
  const isReverse = reverse === 'yes' ? 'reverse' : '';
  const rbiHandlerKey = prismicId || image;
  const subscribeLargeClasses = cx(
    actions && 'has-actions',
    showSdkLinks === 'yes' && 'has-sdks',
    showSubscribeForm === 'yes' && 'has-subscribe-form',
  );

  const renderSdk = () => showSdkLinks === 'yes' && (<Sdks />);

  const renderActions = () => actions && (<ActionButtons actions={actions} />);

  const renderSubscribeForm = () => showSubscribeForm === 'yes' && (
    <div className="subscribe-form">
      <SubscribeForm
        tags={tags}
        actionMessage={actionMessage}
      />
    </div>
  );

  return (
    <div className={`subscribe-large ${subscribeLargeClasses} ${background}`} {...rest}>
      <div className="inner">
        <div className={`layout ${isReverse}`}>
          <div className="content-left">
            <div className="label">{flag}</div>
            <h2 className={titleClasses}>{title}</h2>
            <div className={`description ${textColor}`}>
              {children}
            </div>
            {renderSdk()}
            {renderActions()}
            {renderSubscribeForm()}
          </div>
          <div className="content-right">
            {prismicId
              ? PrismicImage(prismicId, imageSet)
              : BackgroundImage(image)
            }
            <div className={`image rbiHandler-${rbiHandlerKey}`} />
          </div>
        </div>
      </div>
    </div>
  );
};

SubscribeLarge.defaultProps = {
  prismicId: undefined,
  flag: undefined,
  actionMessage: 'Thank you.',
  image: undefined,
  imageSet: undefined,
  showSdkLinks: 'no',
  showSubscribeForm: 'yes',
  actions: [],
};

SubscribeLarge.propTypes = {
  prismicId: PropTypes.string,
  flag: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.oneOf(['default', 'white', 'blue-light', 'clouds-dark', 'clouds-light']).isRequired,
  titleSize: PropTypes.oneOf(['default', 'medium']).isRequired,
  textColor: PropTypes.oneOf(['default', 'white', 'blue-light', 'black', 'grey-med']).isRequired,
  background: PropTypes.oneOf(['default', 'blue', 'blue-dark', 'red']).isRequired,
  reverse: PropTypes.oneOf(['no', 'yes']).isRequired,
  showSdkLinks: PropTypes.oneOf(['no', 'yes']),
  showSubscribeForm: PropTypes.oneOf(['no', 'yes']),

  actions: PropTypes.arrayOf(
    PropTypes.shape(
      Object.assign(ActionButtons.propTypes, {}),
    ),
  ),

  tags: PropTypes.string.isRequired,
  actionMessage: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string.isRequired,
  ]),

  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,

  image: PropTypes.string,
  imageSet: PropTypes.shape(
    Object.assign(PrismicImage.propTypes, {}),
  ),
};

export default SubscribeLarge;
