import React from 'react';
import PropTypes from 'prop-types';

import { LinkToButton } from '../Controls/LinkTo/LinkTo';

const ActionButtons = ({ actions, size }) => (
  <div className="actions">
    <ul>
      {actions.map(action => (
        <li key={action.name}>
          <LinkToButton
            type={action.type.toLowerCase()}
            to={action.to}
            onClick={action.onClick}
            href={action.to}
            color={action.color}
            hovercolor={action.hovercolor}
            size={size}
          >
            {action.name}
          </LinkToButton>
        </li>
      ))}
    </ul>
  </div>
);

ActionButtons.defaultProps = {
  actions:'',
  size:'default',
};

ActionButtons.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape(
      Object.assign(LinkToButton.propTypes, {}),
    ).isRequired,
  ),
  size: PropTypes.string,
};

export default ActionButtons;
