import { Component } from 'react';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import history from '../history';
import AnalyticAction from './AnalyticAction';

// Hubspot
const hsq = window._hsq = window._hsq || []; // eslint-disable-line

class AnalyticListener extends Component {
  componentDidMount() {
    const debug = false; // process.env.REACT_APP_STAGE !== 'production'; // false
    const { tagManagerId, googleAnalyticId } = this.props;
    if (tagManagerId) TagManager.initialize({ gtmId: tagManagerId });

    ReactGA.initialize(googleAnalyticId, { debug });

    this.sendPageView(history.location);
    history.listen(this.sendPageView);
  }

  sendPageView = (location) => {
    const { pathname, hash } = location;
    const page = !hash ? pathname : `${pathname}${hash}`;
    // google analytics
    ReactGA.set({ page });
    ReactGA.pageview(page);

    // mixpanel analytics
    AnalyticAction.track(
      `Viewed page ${page === '/' ? 'landing' : page.substr(1)}`,
      { $page: page, $action: 'viewed' },
    );

    // hubspot analytics
    hsq.push(['setPath', page]);
    hsq.push(['trackPageView']);
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default AnalyticListener;
