import React from 'react';
import PropTypes from 'prop-types';

const NavItem = (props) => {
  const { className, children, ...rest } = props;

  const classes = ('nav-item', className);
  return (
    <li className={classes} {...rest}>
      {children}
    </li>
  );
};

NavItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default NavItem;
