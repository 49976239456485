import React from 'react';
import cx from 'classnames';

const Icon = (props) => {
  const {
    color, name, nav, outline, button, children, ...rest
  } = props;

  const classes = cx(
    `icon_${name}`,
    color,
    nav && 'nav_icon',
    outline && 'outline',
    button && 'button_icon',
  );

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

Icon.defaultProps = {
  nav: false,
  outline: false,
  button: false,
  color: '',
  name: '',
  styles:'',
};

export default Icon;
