import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Halves = (props) => {
  const {
    className, reverse, children, ...rest
  } = props;

  const contentClasses = cx(
    'contents-halves',
    reverse && 'reverse',
    className,
  );

  return (
    <div className={contentClasses} {...rest}>
      {children}
    </div>
  );
};

Halves.defaultProps = {
  className: '',
  reverse:false,
};

Halves.propTypes = {
  className: PropTypes.string,
  reverse: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Halves;
