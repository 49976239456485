import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import { PrismicRoute, PrismicWrapper, PrismicPreviewConfig } from '../../utils';
import { DragonchainConfig, linkResolver } from '../../lib/globals';
import { Footer, Header, Quicknav } from '.';
import { NotFound, Preview, PageBuilder } from '../../pages';

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentWillMount() { smoothscroll.polyfill(); }

  render() {
    return (
      <div className="all">
        <PrismicPreviewConfig endpoint={DragonchainConfig.PRISMIC_API} />
        <div className="inner">
          <Quicknav />
          <Header />
          <PrismicWrapper endpoint={DragonchainConfig.PRISMIC_API} linkResolver={linkResolver}>
            <Switch>
              <PrismicRoute uid="preview" path="/preview" component={Preview} />
              <PrismicRoute exact uid="landing" path="/" component={PageBuilder} />
              <PrismicRoute exact path="/:uid" component={PageBuilder} />
              <Route render={props => <NotFound {...props} />} />
            </Switch>
          </PrismicWrapper>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Main;
