import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ActionButtons from '../../ActionButtons';

const Header = (props) => {
  const {
    title, color, size, width, actions, children, ...rest
  } = props;

  const contentClasses = cx('contents-full', width);
  const titleClasses = cx('title', color, size);

  const renderActions = () => actions && (
  <ActionButtons actions={actions} />
  );

  return (
    <div className={contentClasses} {...rest}>
      <div className="inner">
        <h2 className={titleClasses}>{title}</h2>
        <div className="description">
          {children}
        </div>
        {renderActions()}
      </div>
    </div>
  );
};

Header.defaultProps = {
  color: undefined,
  size: undefined,
  width: undefined,
  actions: [],
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['black', 'white', 'clouds']),
  size: PropTypes.oneOf(['large']),
  width: PropTypes.oneOf(['wide']),
  actions: PropTypes.arrayOf(
    PropTypes.shape(
      Object.assign(ActionButtons.propTypes, {}),
    ),
  ),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Header;
