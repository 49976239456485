import React, { Component } from 'react';
import ReactSVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import { AppStateContext } from '../../../contexts';
import Navbar from '../Navbar';
import logo from '../../../assets/images/brand/dragonchain-foundation.svg';

class Header extends Component {
  static contextType = AppStateContext;

  render() {
    return (
      <AppStateContext.Consumer>
        {(context) => {
          const { initialized, theme } = context.appState;
          return (
            <header className={`header ${theme}`}>
              <Link href to="/">
                <h6 className="logo">
                  <ReactSVG src={logo} />
                </h6>
              </Link>
              {initialized && (<Navbar />)}
            </header>
          );
        }}
      </AppStateContext.Consumer>
    );
  }
}

export default Header;
