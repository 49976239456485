import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Block = (props) => {
  const {
    className, borderTop, background, children, ...rest
  } = props;

  const contentClasses = cx(
    'content-block',
    className,
    background && `bg-${background}`,
    borderTop && `border-top-${borderTop}`,
  );

  return (
    <div className={contentClasses} {...rest}>
      <div className="inner">
        {children}
      </div>
    </div>
  );
};

Block.defaultProps = {
  borderTop: '',
  background: '',
  className: '',
};

Block.propTypes = {
  className: PropTypes.string,
  borderTop: PropTypes.oneOf(['blue', '']),
  background: PropTypes.oneOf(['blue-dark-bokeh', '']),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Block;
