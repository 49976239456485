import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class LinkTo extends Component {
  constructor(props) {
    super(props);
    this.classes = '';

    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    const { disabled, to, onClick } = this.props;
    if (disabled) { e.preventDefault(); return; }
    if (to === '#') { e.preventDefault(); }
    if (onClick) { onClick(e); }
  }

  render() {
    const {
      type, to, blank, disabled, children, ...rest
    } = this.props;
    const Tag = (['document', 'route'].indexOf(type.toLowerCase()) >= 0) ? Link : 'a';

    const noreferrer = ['blank', 'web', 'media'].indexOf(type.toLowerCase()) >= 0
      ? { target: '_blank', rel: 'noreferrer' } : '';

    const options = (['document', 'route'].indexOf(type.toLowerCase()) >= 0)
      ? { to, ...noreferrer } : { onClick: this.onClick, ...noreferrer };

    return (
      <Tag className={this.classes} disabled={disabled} {...options} {...rest}>
        {children}
      </Tag>
    );
  }
}

class LinkToButton extends LinkTo {
  constructor(props) {
    super(props);
    const { color, hovercolor, size } = this.props;

    this.classes = `button ${this.classes} ${color} ${size} hover_${hovercolor}`;
  }
}

LinkTo.defaultProps = {
  to: '',
  disabled: false,
  type: '',
  children:'',
};

LinkTo.propTypes = {
  type: PropTypes.oneOf(['internal', 'document', 'media', 'web', 'route', 'link', 'hash', 'blank', '']),
  to: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

LinkToButton.defaultProps = {
  color: 'default',
  hovercolor: 'default',
  ...LinkTo.defaultProps,
};

LinkToButton.propTypes = {
  ...LinkTo.propTypes,
  color: PropTypes.oneOf(
    [
      'default',
      'black',
      'blue',
      'blue_dark',
      'blue_light',
      'blue_med',
      'red',
      'white',
      'yellow',
      'outline_blue_light',
      'outline',
    ],
  ),
  hovercolor: PropTypes.oneOf(
    [
      'default',
      'blue',
      'blue_dark',
      'blue_light',
      'blue_med',
      'blue_on_white',
      'red',
      'red_on_white',
      'white',
      'yellow',
    ],
  ),
  size: PropTypes.oneOf(['default', 'small']),
};

export { LinkTo, LinkToButton };

/*
class LinkToButtonIcon extends Component { // eslint-disable-line
  constructor(props) {
    super(props);
    const {
      color, hovercolor, size,
    } = this.props;
    this.classes = `button_and_info ${color} ${size} hover_${hovercolor}`;
  }

  render() {
    const {
      to, blank, disabled, children, icon, ...rest
    } = this.props;

    const type = blank ? { type: '_blank', rel: 'noreferrer' } : '';

    return (
      <LinkTo className={this.classes} href={to} disabled={disabled} {...type} {...rest}>
        <div className="button_status">
          <Icon button name={icon} color="white" />
          {children}
        </div>
      </LinkTo>
    );
  }
}

LinkToButtonIcon.defaultProps = {
  ...LinkToButton.defaultProps,
};


LinkToButtonIcon.propTypes = {
  ...LinkToButton.propTypes,
  icon: PropTypes.string.isRequired,
};
*/
