import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

const Quarters = (props) => {
  const { reverse, children, ...rest } = props;

  const contentClasses = cx(
    'contents_quarters',
    reverse && 'reverse',
  );

  return (
    <div className={contentClasses} {...rest}>
      {children}
    </div>
  );
};

Quarters.defaultProps = { reverse:false };

Quarters.propTypes = {
  reverse: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Quarters;
