import React from 'react';
import PropTypes from 'prop-types';

const Half = (props) => {
  const { children, ...rest } = props;

  return (
    <div className="content-half" {...rest}>
      <div className="inner">
        {children}
      </div>
    </div>
  );
};

Half.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Half;
