import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HubspotForm from 'react-hubspot-form';
import { DragonchainConfig } from '../../lib/globals';
import { AnalyticAction } from '../../utils';
import { Page } from '../Containers';

class EngagementForm extends Component {
  handleEvent = (form, action) => {
    AnalyticAction.track(
      `${action} ${form}`,
      { $form: form, $action: action },
    );
  }

  render() {
    const {
      formId, title, children,
    } = this.props;
    return (
      <Page>
        <div className="engagement-inline">
          <div className="layout">
            <div className="engagement-content">
              <div className="title clouds-dark">{title}</div>
              <div className="description">{children}</div>
            </div>
            <div className="engagement-form">
              <HubspotForm
                portalId={DragonchainConfig.PORTAL_ID}
                formId={formId}
                // onSubmit={() => this.handleEvent(engagementName, 'submitted')} // JQUERY!?
                loading={<div>Loading form</div>}
              />
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

export default EngagementForm;

EngagementForm.defaultProps = {
  title: undefined,
  children: undefined,
};

EngagementForm.propTypes = {
  formId: PropTypes.string.isRequired,
  engagementName: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
