export class TOKEN_INVALID extends Error {
  constructor() {
    super();
    this.code = 'TOKEN_INVALID';
    this.userMessage = 'Your token is invalid. Please login.';
  }
}

export class ALREADY_CLAIMED extends Error {
  constructor() {
    super();
    this.code = 'ALREADY_CLAIMED';
    this.userMessage = 'This Ethereum address is already claimed.';
  }
}

export class BAD_REQUEST extends Error {
  constructor(message) {
    super();
    this.code = 'BAD_REQUEST';
    this.userMessage = message || 'Invalid request.';
  }
}

export class INVALID_INPUT extends Error {
  constructor(message) {
    super();
    this.code = 'INVALID_INPUT';
    this.userMessage = message || 'The provided input cannot be used. Please provide valid input and try again.';
  }
}

export class UNAUTHORIZED extends Error {
  constructor() {
    super();
    this.code = 'UNAUTHORIZED';
    this.userMessage = 'Please log-in to perform that action.';
  }
}

export class NOT_FOUND extends Error {
  constructor() {
    super();
    this.code = 'NOT_FOUND';
    this.userMessage = 'The requested resource could not be found.';
  }
}

export class GENERIC_ERROR extends Error {
  constructor() {
    super();
    this.code = 'GENERIC_ERROR';
    this.userMessage = 'Something went wrong. Please try again later.';
  }
}
