import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon } from '../index';

const Label = (props) => {
  const {
    color, complete, name, children, ...rest
  } = props;

  const classes = cx('label', color);
  const innerClasses = cx('inner', (children && 'children'));
  const checkColor = (color === 'white' ? 'blue' : 'white');

  return (
    <div className={classes} {...rest}>
      <div className={innerClasses}>{name}</div>
      {
        complete && (
        <Icon name="circle" color={color}>
          <Icon name="check_small" color={checkColor} />
        </Icon>
        )
      }
      {children}
    </div>
  );
};

Label.defaultProps = {
  color: undefined,
  complete: false,
  children: undefined,
};

Label.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  complete: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Label;
