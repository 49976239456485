import React, { Component } from 'react';
import JojoHero from '../JojoHero';
import { AppStateContext } from '../../contexts';

class NotFoundCard extends Component {
  static contextType = AppStateContext;

  async componentDidMount() {
    const { changeAppState } = this.context;
    const { theme } = this.props;
    changeAppState('theme', theme);
  }

  render() {
    return (
      <div>
        <JojoHero
          title="404: Page Not Found"
          titleColor="clouds"
          textColor="black"
          actions={[{
            type: 'route', to: '/', color: 'red', hovercolor: 'blue', name: 'Back to Dragonchain',
          }]}
        >
          {`The page you are looking for cannot be found. It may have been moved,
          permanently deleted, or in Jojo's treasure chest.`}
        </JojoHero>
      </div>
    );
  }
}

export default NotFoundCard;
