import React from 'react';
import PropTypes from 'prop-types';

const Nav = (props) => {
  const { children, ...rest } = props;

  return (
    <nav {...rest}>
      <ul>
        {children}
      </ul>
    </nav>
  );
};

Nav.defaultProps = {};

Nav.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Nav;
