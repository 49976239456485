import React, { Component } from 'react';
import queryString from 'query-string';
import { Page } from '../../components/Containers';
import { PageMeta } from '../../components/Meta/Meta';

class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  preview = async () => {
    this.setState({ isLoaded: true });
    const { location : { search }, history, prismic: { api, linkResolver } } = this.props;
    const filter = queryString.parse(search);
    const url = await api.previewSession(filter.token, linkResolver, '/');
    history.push(url);
  }

  render() {
    const { prismic } = this.props;
    const { isLoaded } = this.state;
    if (prismic && !isLoaded) this.preview();
    return (
      <Page>
        <PageMeta page="nofollow" />
        <section>
          <br />
          <br />
          <br />
          <p style={{ textAlign: 'center' }}>Loading previews...</p>
        </section>
      </Page>
    );
  }
}
export default Preview;
