import React from 'react';
import PropTypes from 'prop-types';
import RichText from '../../utils/Prismic/reactjs';
import { linkResolver } from '../../lib/globals';
import { Block, Halves, Half } from '../Containers';
import BlockLabel from '../BlockLabel';
import RetinaImage from '../RetinaImage';
import ListItem from '../ListItem';

const FeatureHalves = (props) => {
  const {
    blockLabel, blockLabelColor, listItemSet, image,
    reverse, prismicId, imageSet, ...rest
  } = props;

  const attributes = reverse === 'yes' ? { reverse:true } : { reverse:false };

  const renderListItemSet = () => listItemSet && listItemSet.map(item => (
    <ListItem key={item.title} label={item.label} title={item.title}>
      {
        prismicId
          ? RichText.render(item.text, linkResolver)
          : item.text
      }
    </ListItem>
  ));

  const renderBlockLabel = () => (blockLabel && reverse === 'yes') && (
    <BlockLabel color={blockLabelColor}>
      {blockLabel}
    </BlockLabel>
  );

  return (
    <Block {...rest}>
      <Halves {...attributes}>
        <Half>
          <RetinaImage image={image} imageSet={imageSet} />
        </Half>
        <Half>
          {renderBlockLabel()}
          {renderListItemSet()}
        </Half>
      </Halves>
    </Block>
  );
};

FeatureHalves.defaultProps = {
  blockLabel: undefined,
  blockLabelColor: 'default',
  prismicId: undefined,
  image: undefined,
  imageSet: undefined,
};

FeatureHalves.propTypes = {
  prismicId: PropTypes.string,
  blockLabel: PropTypes.string,
  blockLabelColor: PropTypes.oneOf(['default', 'white', 'blue-light', 'blue', 'grey']),
  reverse: PropTypes.oneOf(['no', 'yes']).isRequired,
  listItemSet: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      text: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.arrayOf(PropTypes.object),
        PropTypes.node,
        PropTypes.object,
      ]).isRequired,
    }),
  ).isRequired,
  image: PropTypes.string,
  imageSet: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string,
    standard: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

export default FeatureHalves;
