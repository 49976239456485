import HttpRequestApi from './HttpRequestApi';
import { DragonchainConfig } from './globals';

const { HUBSPOT_API, PORTAL_ID } = DragonchainConfig;

export default class HubspotApiWrapper {
  constructor() {
    this.api = new HttpRequestApi(HUBSPOT_API);
    this.leadForm = 'ecbfc6f2-5e28-4589-9604-f37551826811';
    this.registerForm = '7dfd5ba6-7ee9-4b23-9299-d0cd8f427ade';
  }

  postLead = async (email, firstName, interest, pageUri) => {
    try {
      const data = {
        fields: [
          { name: 'email', value: email },
          { name: 'firstname', value: firstName },
          { name: 'request_type', value: interest },
        ],
        context: { pageUri, pageName: interest },
      };
      return await this.api.post(`/integration/submit/${PORTAL_ID}/${this.leadForm}`, data);
    } catch (e) {
      return { ok:false };
    }
  }

  postPreRegistration = async (hubspotid, firstname, lastname, email,
    phone, company, jobtitle, plantype, planperiod, pageUri) => {
    try {
      const data = {
        fields: [
          { name: 'firstname', value: firstname },
          { name: 'lastname', value: lastname },
          { name: 'email', value: email },
          { name: 'phone', value: phone },
          { name: 'company', value: company },
          { name: 'jobtitle', value: jobtitle },
          { name: 'plantype', value: plantype },
          { name: 'planperiod', value: planperiod },
        ],
        context: {
          hutk: hubspotid,
          pageUri,
          pageName: 'Pre Registration',
        },
      };
      await this.api.post(`/integration/submit/${PORTAL_ID}/${this.registerForm}`, data);
      return { ok:true };
    } catch (e) {
      return { ok:false };
    }
  }
}
