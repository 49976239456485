import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Input, Button, Icon, Spinner,
} from '../Controls';
import { subscribeApi, hubspotApi } from '../../lib';
import { DragonchainConfig } from '../../lib/globals';
import history from '../../history';

class SubscribeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageUri: `https://${DragonchainConfig.DOMAIN}${history.location.pathname}`,
      actionMessage: '',
      error: '',
      response: '',
      isLoading:false,
      formData:{
        FNAME: '',
        email: '',
      },
    };
  }

  handleOnFocus = () => {
    this.setState({ error: '' });
  }

  handleInputChange = (e, stateItem) => {
    const { value } = e.target;
    this.setState(previousState => ({
      formData: { ...previousState.formData, [stateItem]: value },
    }));
  }

  handleOnClick = async (e) => {
    e.preventDefault();
    const { formData } = this.state;
    const { actionMessage } = this.props;

    if (!formData.FNAME || !formData.email) {
      this.setState({ error: 'All fields are required.' });
      return;
    }

    this.setState({ isLoading: true });
    const { tags: tagsString } = this.props;
    const { pageUri } = this.state;
    const tags = tagsString && tagsString.split(',').map(tag => tag.trim()); // split and remove whitespace
    const { email, ...mergeFields } = formData;

    const response = await subscribeApi.createMember(email, mergeFields, tags);
    const message = response ? actionMessage : 'An error occured';

    hubspotApi.postLead(email, mergeFields.FNAME, tagsString, pageUri); // no need to await response
    this.setState({ actionMessage: message, response, isLoading: false });
  }

  render() {
    const {
      buttonText, buttonColor, hoverColor, children,
    } = this.props;
    const {
      actionMessage, error, response, isLoading,
    } = this.state;

    return (
      <form
        method="post"
        encType="multipart/form-data"
        onFocus={() => this.handleOnFocus()}
        onSubmit={(e) => { this.handleOnClick(e); }}
        autoComplete="off"
        className={response !== '' ? 'has-response' : ''}
      >
        {response !== '' && <div className="message-response">{actionMessage}</div>}
        <div className="fields-inline">
          {children}
          <div className="field">
            <Input
              type="text"
              name="FNAME"
              placeholder="First name..."
              onChange={e => this.handleInputChange(e, 'FNAME')}
            />
          </div>
          <div className="field">
            <Input
              type="email"
              name="email"
              placeholder="Email address..."
              onChange={e => this.handleInputChange(e, 'email')}
            />
          </div>
          <div className="action">
            {isLoading
              ? <Spinner color="default" />
              : (
                <div className="button-style">
                  <Button type="submit" small color={buttonColor} hover={hoverColor}>{buttonText}</Button>
                </div>
              )
            }
          </div>
        </div>
        { error && (
          <div className="message-alert" role="alert">
            <Icon name="caution" color="yellow" />
            {error}
          </div>
        )}
      </form>
    );
  }
}

SubscribeForm.defaultProps = {
  buttonColor: '',
  hoverColor: '',
  buttonText: '',
  children: undefined,
};

SubscribeForm.propTypes = {
  buttonColor: PropTypes.string,
  hoverColor: PropTypes.string,
  buttonText: PropTypes.string,
  tags: PropTypes.string.isRequired, // comma delemited string
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  actionMessage: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string.isRequired,
  ]).isRequired,
};

export default SubscribeForm;
