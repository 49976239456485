import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import imageLibrary from '../../assets/images';
import RichText from '../../utils/Prismic/reactjs';
import { DragonchainConfig } from '../../lib/globals';
import metadata from './Metadata.json';

const SocialGeneral = (url, description, image, title) => (
  <Helmet key="social-general">
    <meta property="og:title" content={RichText.asText(title)} />
    <meta property="og:url" content={url} />
    <meta property="og:image" content={image.url} />
    <meta property="og:description" content={RichText.asText(description)} />
    <meta property="og:type" content="website" />
  </Helmet>
);

const SocialTwitter = (url, description, image, title) => (
  <Helmet key="social-twitter">
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@dragonchaingang" />
    <meta name="twitter:title" content={RichText.asText(title)} />
    <meta name="twitter:description" content={RichText.asText(description)} />
    <meta name="twitter:creator" content="@dragonchaingang" />
    <meta name="twitter:image" content={image.large_image.url} />
    <meta name="twitter:url" content={url} />
  </Helmet>
);

const SocialDefault = (url, title, description) => (
  <Helmet key="social-default">
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content="https://dragonchain.com/static/media/dragonchain-logo-4000.png" />
    <meta property="og:url" content={url} />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:site" content="@dragonchaingang" />
    <meta name="twitter:image" content="https://dragonchain.com/static/media/dragonchain-logo-4000.png" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
  </Helmet>
);

const ArticleMeta = ({
  title, primaryCategory, datePublished, dateModified, description, imageSet,
}) => {
  const { standard: defaultStandard, retina: defaultRetina } = imageLibrary['article-card-default'];
  const standard = imageSet ? imageSet.standard.url : `https://${DragonchainConfig.DOMAIN}${defaultStandard}`;
  const retina = imageSet ? imageSet.url : `https://${DragonchainConfig.DOMAIN}${defaultRetina}`;

  return (
    <Helmet>
      <meta property="og:type" content="article" />
      <meta property="article:published_time" content={datePublished} />
      <meta property="article:modified_time" content={dateModified} />
      <meta property="og:tag" content={primaryCategory} />
      <meta property="article:section" content="Blockchain" />
      <script type="application/ld+json">
        {`{
          "@context": "https://schema.org",
          "@type": "NewsArticle",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://dragonchain.com"
          },
          "headline": "${title}",
          "image": [
            "${retina}",
            "${standard}"
          ],
          "datePublished": "${datePublished}",
          "dateModified": "${dateModified}",
          "author": {
            "@type": "Organization",
            "name": "Dragonchain"
          },
          "publisher": {
            "@type": "Organization",
            "name": "Dragonchain",
            "logo": {
              "@type": "ImageObject",
              "url": "https://dragonchain.com/static/media/dragonchain-wordmark.png"
            }
          },
          "description": "${description}"
        }`}
      </script>
    </Helmet>
  );
};

const PageMeta = ({
  page, pageUrl, title, description, isCrawlable, cardSet,
}) => {
  const currentPage = metadata[page] ? metadata[page] : metadata[''];
  const metaTitle = title || currentPage.title;
  const metaDescription = description || currentPage.description;
  const metaIsCrawlable = title ? isCrawlable : currentPage.isCrawlable;
  const url = pageUrl || `https://${DragonchainConfig.DOMAIN}${currentPage.path}`;
  const socialCards = cardSet ? cardSet.map((card) => {
    const { slice_type: type, primary } = card;
    switch (type) {
      case 'twitter_card':
        return SocialTwitter(url, primary.description, primary.image, primary.title);
      case 'general_card':
        return SocialGeneral(url, primary.description, primary.image, primary.title);
      default:
        return '';
    }
  }) : SocialDefault(url, metaTitle, metaDescription);

  return (
    <React.Fragment>
      <Helmet key="page-meta">
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href={url} />
        {!metaIsCrawlable && <meta name="robots" content="noindex" />}
      </Helmet>
      {socialCards}
    </React.Fragment>
  );
};

PageMeta.defaultProps = {
  pageUrl: '',
  title: '',
  description: '',
  isCrawlable: false,
  page: '',
  cardSet: undefined,
};

PageMeta.propTypes = {
  pageUrl: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  isCrawlable: PropTypes.bool,
  page: PropTypes.string,
  cardSet: PropTypes.arrayOf(
    PropTypes.shape({
      items: PropTypes.array,
      primary: PropTypes.shape({
        card_type: PropTypes.string,
        description: PropTypes.any.isRequired,
        image: PropTypes.any,
        title: PropTypes.any.isRequired,
      }),
      slice_type: PropTypes.string,
    }).isRequired,
  ),
};

export { PageMeta, ArticleMeta };
