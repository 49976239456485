const context = require.context('./content', true, /.jpg|.png$/);

const dictonary = {};
context.keys().forEach((key) => {
  // remove the first 2 characters, remove the file extension, split
  const image = key.split('./').pop().substring(0, key.length - 6).split('@');
  const size = (image[1] === undefined ? 'standard' : image[1].replace('2x', 'retina'));

  if (dictonary[image[0]]) {
    dictonary[image[0]][size] = context(key);
  } else { dictonary[image[0]] = { [size]: context(key) }; }
});

export default dictonary;
