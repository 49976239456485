import React, { Component } from 'react';
import { AppStateContext } from '../../contexts';

class LoadingCard extends Component {
  static contextType = AppStateContext;

  async componentDidMount() {
    const { changeAppState } = this.context;
    const { theme } = this.props;
    changeAppState('theme', theme);
  }

  render() {
    return (
      <div style={{ paddingTop:'500px' }} />
    );
  }
}

export default LoadingCard;
