import React, { createContext } from 'react';
import Prismic from 'prismic-javascript';

const PrismicContext = createContext();

export default class PrismicWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prismic: null,
    };
  }

  componentDidMount = async () => { await this.buildContext(); }

  refreshContext = async () => { await this.buildContext(); }

  buildContext = async () => {
    const { endpoint, linkResolver } = this.props;
    const api = await Prismic.api(endpoint);
    if (api.error) { return; }

    const prismic = {
      api,
      endpoint,
      linkResolver: linkResolver || ((doc, ctx) => '/'),  // eslint-disable-line
      predicates: Prismic.Predicates,
    };
    this.setState({ prismic });
  }

  render() {
    const {
      props: { children },
      state: { prismic },
      refreshContext,
    } = this;

    return (
      <PrismicContext.Provider value={{ prismic, refreshContext }}>
        {children}
      </PrismicContext.Provider>
    );
  }
}

const PrismicConsumer = PrismicContext.Consumer;

export { PrismicContext, PrismicConsumer };
