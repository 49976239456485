import React from 'react';
import BlockLabel from '../BlockLabel';

const CompetitorTable = (props) => {
  const { ...rest } = props;

  return (
    <div className="feature-chart" {...rest}>
      <div className="inner">
        <BlockLabel>Feature Comparison</BlockLabel>
        <div className="title">
          Dragonchain vs. Other Platforms
        </div>
        <div className="feature-chart-table">
          <div className="inner">
            <div className="head">
              <div className="column feature blue platform">Features</div>
              <div className="column blue platform dragonchain">Dragonchain</div>
              <div className="column platform">Hyperledger Fabric</div>
              <div className="column platform">Ethereum</div>
              <div className="column platform">R3 Corda</div>
            </div>
            <div className="row">
              <div className="column feature">Deployment</div>
              <div className="column blue dragonchain">Hybrid</div>
              <div className="column no">Private only</div>
              <div className="column no">Public only</div>
              <div className="column no">Private only</div>
            </div>
            <div className="row highlight">
              <div className="column feature">Fast Transaction Speeds</div>
              <div className="column blue dragonchain">Yes</div>
              <div className="column no">No</div>
              <div className="column no">No</div>
              <div className="column no">Yes</div>
            </div>
            <div className="row">
              <div className="column feature">Smart Contract Languages</div>
              <div className="column blue dragonchain">
                <p>
                  Any language including
                  <em>Node.js, Python, Go, Java, C#</em>
                </p>
              </div>
              <div className="column no">Node.js, Java, Go</div>
              <div className="column no">Solidity</div>
              <div className="column no">Kotlin</div>
            </div>
            <div className="row">
              <div className="column feature">Interoperability with Other Blockchains</div>
              <div className="column blue dragonchain">
                <p>
                  Yes
                  <em>Interchain Patent</em>
                </p>
              </div>
              <div className="column no">No</div>
              <div className="column no">No</div>
              <div className="column no">No</div>
            </div>
            <div className="row">
              <div className="column feature">Strong Transactional Proof</div>
              <div className="column blue dragonchain">
                <p>
                  Yes, combined security
                  <em>BTC, ETH, NEO, ETC</em>
                </p>
              </div>
              <div className="column no">No</div>
              <div className="column no">Yes</div>
              <div className="column no">No</div>
            </div>
            <div className="row">
              <div className="column feature">Method of Business Data Protection</div>
              <div className="column blue dragonchain">
                <p>
                  Architectural
                  <em>Privacy by Design</em>
                </p>
              </div>
              <div className="column no">
                <p>
                  Closed Network
                  <em>Permissioned</em>
                </p>
              </div>
              <div className="column no">None</div>
              <div className="column no">
                <p>
                  Closed Network
                  <em>Permissioned</em>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="column feature">Containerized Smart Contract</div>
              <div className="column blue dragonchain">Yes</div>
              <div className="column no">No</div>
              <div className="column no">No</div>
              <div className="column no">No</div>
            </div>
          </div>
        </div>

        <div className="feature-chart-table phone-only">
          <div className="inner">
            <div className="head">
              <div className="column feature blue platform">Features</div>
              <div className="column blue platform dragonchain">Dragonchain</div>
              {/* <div className="column platform">Hyperledger Fabric</div> */}
              {/* <div className="column platform">Ethereum</div> */}
              {/* <div className="column platform">R3 Corda</div> */}
            </div>
            <div className="row">
              <div className="column feature">Deployment</div>
              <div className="column blue dragonchain">Hybrid</div>
              {/* <div className="column no">Private only</div> */}
              {/* <div className="column no">Public only</div> */}
              {/* <div className="column no">Private only</div> */}
            </div>
            <div className="row highlight">
              <div className="column feature">Fast Transaction Speeds</div>
              <div className="column blue dragonchain">Yes</div>
              {/* <div className="column no">No</div> */}
              {/* <div className="column no">No</div> */}
              {/* <div className="column no">Yes</div> */}
            </div>
            <div className="row">
              <div className="column feature">Smart Contract Languages</div>
              <div className="column blue dragonchain">
                <p>
                  Any language including
                  <em>Node.js, Python, Go, Java, C#</em>
                </p>
              </div>
              {/* <div className="column no">Node, Java, Go</div> */}
              {/* <div className="column no">Solidity</div> */}
              {/* <div className="column no">Kotlin</div> */}
            </div>
            <div className="row">
              <div className="column feature">Interoperability with Other Blockchains</div>
              <div className="column blue dragonchain">
                <p>
                  Yes
                  <em>Interchain Patent</em>
                </p>
              </div>
              {/* <div className="column no">No</div> */}
              {/* <div className="column no">No</div> */}
              {/* <div className="column no">No</div> */}
            </div>
            <div className="row">
              <div className="column feature">Strong Transactional Proof</div>
              <div className="column blue dragonchain">
                <p>
                  Yes, combined security
                  <em>BTC, ETH, NEO, ETC</em>
                </p>
              </div>
              {/* <div className="column no">No</div> */}
              {/* <div className="column no">Yes</div> */}
              {/* <div className="column no">No</div> */}
            </div>
            <div className="row">
              <div className="column feature">Method of Business Data Protection</div>
              <div className="column blue dragonchain">
                <p>
                  Architectural
                  <em>Privacy by Design</em>
                </p>
              </div>
              {/* <div className="column no">
                <p>
                  Closed Network
                  <em>Permissioned</em>
                </p>
              </div> */}
              {/* <div className="column no">None</div> */}
              {/* <div className="column no">
                <p>
                  Closed Network
                  <em>Permissioned</em>
                </p>
              </div> */}
            </div>
            <div className="row">
              <div className="column feature">Containerized Smart Contract</div>
              <div className="column blue dragonchain">Yes</div>
              {/* <div className="column no">No</div> */}
              {/* <div className="column no">No</div> */}
              {/* <div className="column no">No</div> */}
            </div>
          </div>
        </div>

        <div className="feature-chart-table phone-only">
          <div className="inner">
            <div className="head">
              <div className="column feature blue platform">Features</div>
              {/* <div className="column blue platform dragonchain">Dragonchain</div> */}
              <div className="column platform">Hyperledger Fabric</div>
              {/* <div className="column platform">Ethereum</div> */}
              {/* <div className="column platform">R3 Corda</div> */}
            </div>
            <div className="row">
              <div className="column feature">Deployment</div>
              {/* <div className="column blue dragonchain">Hybrid</div> */}
              <div className="column no">Private only</div>
              {/* <div className="column no">Public only</div> */}
              {/* <div className="column no">Private only</div> */}
            </div>
            <div className="row highlight">
              <div className="column feature">Fast Transaction Speeds</div>
              {/* <div className="column blue dragonchain">Yes</div> */}
              <div className="column no">No</div>
              {/* <div className="column no">No</div> */}
              {/* <div className="column no">Yes</div> */}
            </div>
            <div className="row">
              <div className="column feature">Smart Contract Languages</div>
              {/* <div className="column blue dragonchain">
                <p>
                  Any language including
                  <em>Node.js, Python, Go, Java, C#</em>
                </p>
              </div> */}
              <div className="column no">Node, Java, Go</div>
              {/* <div className="column no">Solidity</div> */}
              {/* <div className="column no">Kotlin</div> */}
            </div>
            <div className="row">
              <div className="column feature">Interoperability with Other Blockchains</div>
              {/* <div className="column blue dragonchain">
                <p>
                  Yes
                  <em>Interchain Patent</em>
                </p>
              </div> */}
              <div className="column no">No</div>
              {/* <div className="column no">No</div> */}
              {/* <div className="column no">No</div> */}
            </div>
            <div className="row">
              <div className="column feature">Strong Transactional Proof</div>
              {/* <div className="column blue dragonchain">
                <p>
                  Yes, combined security
                  <em>BTC, ETH, NEO, ETC</em>
                </p>
              </div> */}
              <div className="column no">No</div>
              {/* <div className="column no">Yes</div> */}
              {/* <div className="column no">No</div> */}
            </div>
            <div className="row">
              <div className="column feature">Method of Business Data Protection</div>
              {/* <div className="column blue dragonchain">
                <p>
                  Architectural
                  <em>Privacy by Design</em>
                </p>
              </div> */}
              <div className="column no">
                <p>
                  Closed Network
                  <em>Permissioned</em>
                </p>
              </div>
              {/* <div className="column no">None</div> */}
              {/* <div className="column no">
                <p>
                  Closed Network
                  <em>Permissioned</em>
                </p>
              </div> */}
            </div>
            <div className="row">
              <div className="column feature">Containerized Smart Contract</div>
              {/* <div className="column blue dragonchain">Yes</div> */}
              <div className="column no">No</div>
              {/* <div className="column no">No</div> */}
              {/* <div className="column no">No</div> */}
            </div>
          </div>
        </div>

        <div className="feature-chart-table phone-only">
          <div className="inner">
            <div className="head">
              <div className="column feature blue platform">Features</div>
              {/* <div className="column blue platform dragonchain">Dragonchain</div> */}
              {/* <div className="column platform">Hyperledger Fabric</div> */}
              <div className="column platform">Ethereum</div>
              {/* <div className="column platform">R3 Corda</div> */}
            </div>
            <div className="row">
              <div className="column feature">Deployment</div>
              {/* <div className="column blue dragonchain">Hybrid</div> */}
              {/* <div className="column no">Private only</div> */}
              <div className="column no">Public only</div>
              {/* <div className="column no">Private only</div> */}
            </div>
            <div className="row highlight">
              <div className="column feature">Fast Transaction Speeds</div>
              {/* <div className="column blue dragonchain">Yes</div> */}
              {/* <div className="column no">No</div> */}
              <div className="column no">No</div>
              {/* <div className="column no">Yes</div> */}
            </div>
            <div className="row">
              <div className="column feature">Smart Contract Languages</div>
              {/* <div className="column blue dragonchain">
                <p>
                  Any language including
                  <em>Node.js, Python, Go, Java, C#</em>
                </p>
              </div> */}
              {/* <div className="column no">Node, Java, Go</div> */}
              <div className="column no">Solidity</div>
              {/* <div className="column no">Kotlin</div> */}
            </div>
            <div className="row">
              <div className="column feature">Interoperability with Other Blockchains</div>
              {/* <div className="column blue dragonchain">
                <p>
                  Yes
                  <em>Interchain Patent</em>
                </p>
              </div> */}
              {/* <div className="column no">No</div> */}
              <div className="column no">No</div>
              {/* <div className="column no">No</div> */}
            </div>
            <div className="row">
              <div className="column feature">Strong Transactional Proof</div>
              {/* <div className="column blue dragonchain">
                <p>
                  Yes, combined security
                  <em>BTC, ETH, NEO, ETC</em>
                </p>
              </div> */}
              {/* <div className="column no">No</div> */}
              <div className="column no">Yes</div>
              {/* <div className="column no">No</div> */}
            </div>
            <div className="row">
              <div className="column feature">Method of Business Data Protection</div>
              {/* <div className="column blue dragonchain">
                <p>
                  Architectural
                  <em>Privacy by Design</em>
                </p>
              </div> */}
              {/* <div className="column no">
                <p>
                  Closed Network
                  <em>Permissioned</em>
                </p>
              </div> */}
              <div className="column no">None</div>
              {/* <div className="column no">
                <p>
                  Closed Network
                  <em>Permissioned</em>
                </p>
              </div> */}
            </div>
            <div className="row">
              <div className="column feature">Containerized Smart Contract</div>
              {/* <div className="column blue dragonchain">Yes</div> */}
              {/* <div className="column no">No</div> */}
              <div className="column no">No</div>
              {/* <div className="column no">No</div> */}
            </div>
          </div>
        </div>

        <div className="feature-chart-table phone-only">
          <div className="inner">
            <div className="head">
              <div className="column feature blue platform">Features</div>
              {/* <div className="column blue platform dragonchain">Dragonchain</div> */}
              {/* <div className="column platform">Hyperledger Fabric</div> */}
              {/* <div className="column platform">Ethereum</div> */}
              <div className="column platform">R3 Corda</div>
            </div>
            <div className="row">
              <div className="column feature">Deployment</div>
              {/* <div className="column blue dragonchain">Hybrid</div> */}
              {/* <div className="column no">Private only</div> */}
              {/* <div className="column no">Public only</div> */}
              <div className="column no">Private only</div>
            </div>
            <div className="row highlight">
              <div className="column feature">Fast Transaction Speeds</div>
              {/* <div className="column blue dragonchain">Yes</div> */}
              {/* <div className="column no">No</div> */}
              {/* <div className="column no">No</div> */}
              <div className="column no">Yes</div>
            </div>
            <div className="row">
              <div className="column feature">Smart Contract Languages</div>
              {/* <div className="column blue dragonchain">
                <p>
                  Any language including
                  <em>Node.js, Python, Go, Java, C#</em>
                </p>
              </div> */}
              {/* <div className="column no">Node, Java, Go</div> */}
              {/* <div className="column no">Solidity</div> */}
              <div className="column no">Kotlin</div>
            </div>
            <div className="row">
              <div className="column feature">Interoperability with Other Blockchains</div>
              {/* <div className="column blue dragonchain">
                <p>
                  Yes
                  <em>Interchain Patent</em>
                </p>
              </div> */}
              {/* <div className="column no">No</div> */}
              {/* <div className="column no">No</div> */}
              <div className="column no">No</div>
            </div>
            <div className="row">
              <div className="column feature">Strong Transactional Proof</div>
              {/* <div className="column blue dragonchain">
                <p>
                  Yes, combined security
                  <em>BTC, ETH, NEO, ETC</em>
                </p>
              </div> */}
              {/* <div className="column no">No</div> */}
              {/* <div className="column no">Yes</div> */}
              <div className="column no">No</div>
            </div>
            <div className="row">
              <div className="column feature">Method of Business Data Protection</div>
              {/* <div className="column blue dragonchain">
                <p>
                  Architectural
                  <em>Privacy by Design</em>
                </p>
              </div> */}
              {/* <div className="column no">
                <p>
                  Closed Network
                  <em>Permissioned</em>
                </p>
              </div> */}
              {/* <div className="column no">None</div> */}
              <div className="column no">
                <p>
                  Closed Network
                  <em>Permissioned</em>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="column feature">Containerized Smart Contract</div>
              {/* <div className="column blue dragonchain">Yes</div> */}
              {/* <div className="column no">No</div> */}
              {/* <div className="column no">No</div> */}
              <div className="column no">No</div>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
};

export default CompetitorTable;
