import React from 'react';
import PropTypes from 'prop-types';

const Page = (props) => {
  const { children, ...rest } = props;

  return (
    <article className="page" {...rest}>
      <div className="inner">
        {children}
      </div>
    </article>
  );
};

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Page;
