import React from 'react';
import PropTypes from 'prop-types';
import ActionButtons from '../ActionButtons';
import { Block } from '../Containers';
import { PrismicImage } from '../../utils';
import Title from '../Title';
import VideoPlayer from '../VideoPlayer';

const Video = (props) => {
  const {
    actions, title, image, children,
    reverse, prismicId, posterSet, poster, url,
    ...rest
  } = props;

  const isReverse = reverse === 'yes' ? 'reverse' : '';

  const renderActions = () => actions && (
    <ActionButtons actions={actions} />
  );

  const renderTitle = () => title && (
    <Title tag="h2" titleColor="white" titleSize="default">
      {title}
    </Title>
  );

  const renderContent = () => (title && children) && (
    <div className="content-40">
      {renderTitle()}
      <div className="description white">
        {children}
      </div>
      {renderActions()}
    </div>
  );

  return (
    <Block {...rest}>
      <div className={`contents-60-40 has-video clouds ${isReverse}`}>
        <div className="content-60">
          <VideoPlayer url={url} poster={poster} posterSet={posterSet} prismicId={prismicId} />
        </div>
        {renderContent()}
      </div>
    </Block>
  );
};

Video.defaultProps = {
  actions: [],
  prismicId: '',
  title: '',
  image: '',
  posterSet: undefined,
  poster: '',
  url: '',
  children: undefined,
};

Video.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  reverse: PropTypes.oneOf(['no', 'yes']).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape(
      Object.assign(ActionButtons.propTypes, {}),
    ),
  ),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  prismicId: PropTypes.string,
  posterSet: PropTypes.shape(
    Object.assign(PrismicImage.propTypes, {}),
  ),
  poster: PropTypes.string,
  url: PropTypes.string,
};

export default Video;
