import React from 'react';
import PropTypes from 'prop-types';

const Title = (props) => {
  const {
    tag, titleColor, titleSize, children, ...rest
  } = props;
  const Tag = tag;
  return (
    <Tag className={`title ${titleSize} ${titleColor}`} {...rest}>
      {children}
    </Tag>
  );
};

Title.defaultProps = {
  tag:'h2',
};

Title.propTypes = {
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'div']),
  titleColor: PropTypes.oneOf(['default', 'black', 'white', 'blue-light', 'clouds-light', 'clouds-dark', '']).isRequired,
  titleSize: PropTypes.oneOf(['default', 'large', 'medium', 'small', '']).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Title;
