import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Flickity from 'react-flickity-component';
import Quote from '../Quote/Quote';
import RichText from '../../utils/Prismic/reactjs';

const flickityOptions = {
  draggable: false,
  wrapAround: false,
  percentPosition: false,
  cellAlign: 0.001,
  contain: true,
  autoPlay: 6000,
  adaptiveHeight: true,
};

class MultiQuote extends Component {
  quoteSlices = quotes => quotes && quotes.map((article, id) => {
    const key = `${id}-quote`;
    const {
      block_label: blockLabel, block_label_color: blockLabelColor,
      author, image, text,
    } = article;
    window.dispatchEvent(new Event('resize'));

    return (
      <Quote
        key={key}
        blockLabel={blockLabel}
        blockLabelColor={blockLabelColor}
        author={author}
        imageSet={image}
      >
        {RichText.asText(text)}
      </Quote>
    );
  });

  render() {
    const { items } = this.props;
    return (
      <div className="multi-quote">
        <div className="inner">
          <Flickity
            flickityRef={c => this.flkty = c} // eslint-disable-line
            options={flickityOptions} // takes flickity options {}
            reloadOnUpdate
          >
            {this.quoteSlices(items)}
          </Flickity>
        </div>
      </div>
    );
  }
}

MultiQuote.defaultProps = {
  items: '',
};

MultiQuote.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      blockLabel: PropTypes.string,
      blockLabelColor: PropTypes.oneOf(['default', 'white', 'blue-light', 'blue', 'grey']),
      author: PropTypes.string.isRequired,
      text: PropTypes.oneOfType([
        PropTypes.any,
      ]).isRequired,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string,
        standard: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      }),
    }),
  ),
};
export default MultiQuote;
