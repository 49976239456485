import dragonchainConfig from './dragonchainConfig.json';
import * as Errors from './errors';
import { externalLink, linkResolver } from './LinkResolver';

const env = process.env.REACT_APP_STAGE === undefined ? 'local' : process.env.REACT_APP_STAGE;
const DragonchainConfig = dragonchainConfig[env];

export {
  DragonchainConfig, Errors, externalLink, linkResolver, env,
};
