import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HubspotForm from 'react-hubspot-form';
import { DragonchainConfig } from '../../lib/globals';
import history from '../../history';
import { AppStateContext } from '../../contexts';
import Modal from '../Modal';
import { AnalyticAction } from '../../utils';

class EngagementModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changeAppState: undefined,
      initalized: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  handleEvent = (form, action) => {
    AnalyticAction.track(`${action} ${form}`, { $form: form, $action: action });
  }

  toggleModal = (engagementName) => {
    const { changeAppState } = this.state;
    changeAppState(engagementName, false);
    history.push({ search: '' });
  }

  render() {
    const { initalized } = this.state;
    const {
      formId, engagementName, title, children,
    } = this.props;
    return (
      <AppStateContext.Consumer>
        {(context) => {
          const { changeAppState, appState } = context;
          if (!initalized) {
            this.setState({ changeAppState, initalized:true });
          }
          return (
            <div className="engagement-modal">
              <Modal
                show={appState[engagementName]}
                closeCallback={() => this.toggleModal(engagementName)}
              >
                <div className="modal_header">
                  <div className="modal_name">{title}</div>
                </div>
                <div className="modal_content">
                  {children}
                  <HubspotForm
                    portalId={DragonchainConfig.PORTAL_ID}
                    formId={formId}
                    // onSubmit={() => this.handleEvent(engagementName, 'submitted')} // JQUERY!?
                    loading={<div>Loading form</div>}
                  />
                </div>
              </Modal>
            </div>
          );
        }}
      </AppStateContext.Consumer>
    );
  }
}

export default EngagementModal;

EngagementModal.propTypes = {
  formId: PropTypes.string.isRequired,
  engagementName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
