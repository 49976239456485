/*
  This event bus will be what handles all events
  that we do not want to tie up the event loops with.
  Anything that needs a central monitoring place,
  will be tracked here and subscribed to wherever
  necessary.
*/

const LISTENING_FOR_RESIZE = Symbol('LISTENING_FOR_RESIZE');
const HANDLE_DEBOUNCE_RESIZE = Symbol('HANDLE_DEBOUNCE_RESIZE');
const listeners = {};

const EventBus = {
  [LISTENING_FOR_RESIZE]: false,

  on(key, fn, attenuation = 'resize') {
    const attenuatedKey = `${key}_${attenuation}`;
    if (!listeners[attenuatedKey]) {
      listeners[attenuatedKey] = [];
    }
    listeners[attenuatedKey] = [...listeners[attenuatedKey], fn];
  },

  listenForResize() {
    if (this[LISTENING_FOR_RESIZE]) return;
    window.addEventListener('resize', this[HANDLE_DEBOUNCE_RESIZE], false);
    this[LISTENING_FOR_RESIZE] = true;
  },
  stopListeningForResize() {
    if (!this[LISTENING_FOR_RESIZE]) return;
    window.removeEventListener('resize', this[HANDLE_DEBOUNCE_RESIZE]);
    this[LISTENING_FOR_RESIZE] = false;
  },
  getBreakpoints() {
    const mobile = window.matchMedia('(max-width: 968px)').matches;
    const tablet = window.matchMedia('(min-width: 769px) and (max-width: 1023px)').matches;
    const desktop = window.matchMedia('(min-width: 1024px)').matches;

    return {
      mobile, tablet, desktop,
    };
  },
};

export default EventBus;
