import React from 'react';
import { Route } from 'react-router-dom';
import { AppStateContext } from '../../contexts';
import { PrismicConsumer } from './PrismicContext';

class PrismicRoute extends React.Component {
  static contextType = AppStateContext;

  render() {
    const {
      component: Component, uid, path, ...rest
    } = this.props;
    const { changeAppState } = this.context;
    return (
      <PrismicConsumer>
        {({ prismic, refreshContext }) => (
          <Route
            path={path}
            render={props => (
              <Component
                uid={uid}
                prismic={prismic}
                refreshPrismic={refreshContext}
                changeAppState={changeAppState}
                {...props}
              />
            )}
            {...rest}
          />
        )}
      </PrismicConsumer>
    );
  }
}

export default PrismicRoute;
