import React from 'react';
import PropTypes from 'prop-types';

const Flag = (props) => {
  const { large, title, ...rest } = props;

  return (
    <div className={`flag ${large && 'large'}`} {...rest}>
      <div className="inner">{title}</div>
    </div>
  );
};

Flag.defaultProps = { large:false };

Flag.propTypes = {
  title: PropTypes.string.isRequired,
  large: PropTypes.bool,
};

export default Flag;
