import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Prism from 'prismjs';
// import 'prism-languages';
import '../../../assets/styles/vendors/prism.css';

class Code extends Component {
  componentDidUpdate() {
    Prism.highlightAll();
  }

  render() {
    const { script, language, ...rest } = this.props;

    return (
      <div className="code-language" {...rest}>
        <pre>
          <code className={`language-${language}`}>
            {script}
          </code>
        </pre>
      </div>
    );
  }
}

Code.propTypes = {
  script: PropTypes.string.isRequired,
  language: PropTypes.oneOf(['py', 'js', 'json']).isRequired,
};

export default Code;
