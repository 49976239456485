import React from 'react';
import PropTypes from 'prop-types';
import ActionButtons from '../ActionButtons';
import { Flag } from '../Controls';
import { Block } from '../Containers';
import BlockLabel from '../BlockLabel';
import { PrismicImage, BackgroundImage } from '../../utils';
import Title from '../Title';

const Feature = (props) => {
  const {
    actions, blockLabel, blockLabelColor,
    title, titleColor, titleSize, textColor,
    background, image, imageSize, flag, children,
    reverse, prismicId, imageSet, ...rest
  } = props;

  const isReverse = reverse === 'yes' ? 'reverse' : '';
  const size60 = imageSize === 'small' ? 'has-small-image' : 'has-large-image';
  const rbiHandlerKey = prismicId || image;

  const renderActions = () => actions && (
    <ActionButtons actions={actions} />
  );

  const renderBlockLabel = () => (blockLabel && reverse === 'yes') && (
    <BlockLabel color={blockLabelColor}>
      {blockLabel}
    </BlockLabel>
  );

  return (
    <Block {...rest}>
      <div className={`contents-60-40 ${isReverse} ${size60}`}>
        <div className="content-60">
          {prismicId
            ? PrismicImage(prismicId, imageSet)
            : BackgroundImage(image)
          }
          <div className={`photo-cover rbiHandler-${rbiHandlerKey}`} />
        </div>

        <div className={`content-40 ${background}`}>
          {renderBlockLabel()}
          {flag && <Flag large title={flag} />}
          <Title titleColor={titleColor} titleSize={titleSize}>
            {title}
          </Title>
          <div className={`description ${textColor}`}>
            {children}
          </div>
          {renderActions()}
        </div>
      </div>
    </Block>
  );
};

Feature.defaultProps = {
  blockLabel: undefined,
  blockLabelColor: 'blue',
  flag: undefined,
  actions: [],
  prismicId: '',
  image: '',
  imageSet: undefined,
};

Feature.propTypes = {
  blockLabel: PropTypes.string,
  blockLabelColor: PropTypes.oneOf(['default', 'white', 'blue-light', 'blue', 'grey']),
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.oneOf(['default', 'black', 'white', 'blue-light', 'clouds-dark', 'clouds-light']).isRequired,
  titleSize: PropTypes.oneOf(['default', 'medium']).isRequired,
  textColor: PropTypes.oneOf(['default', 'white', 'blue-light', 'black', 'grey-med']).isRequired,
  background: PropTypes.oneOf(['default', 'white', 'grey-faint', 'clouds']).isRequired,
  flag: PropTypes.string,
  image: PropTypes.string,
  imageSize: PropTypes.oneOf(['small', 'large']).isRequired,
  reverse: PropTypes.oneOf(['no', 'yes']).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape(
      Object.assign(ActionButtons.propTypes, {}),
    ),
  ),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,

  prismicId: PropTypes.string,
  imageSet: PropTypes.shape(
    Object.assign(PrismicImage.propTypes, {}),
  ),
};


export default Feature;
