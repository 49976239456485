import React from 'react';
import PropTypes from 'prop-types';
import imageLibrary from '../../assets/images';

const Image = (props) => {
  const { image, imageSet, alt } = props;

  if (image && !imageLibrary[image]) return 'IMAGE NOT FOUND';

  const retina = imageSet ? imageSet.url : imageLibrary[image].retina;
  const standard = imageSet ? imageSet.standard.url : imageLibrary[image].standard;
  const phone = imageSet ? imageSet.phone.url : imageLibrary[image].phone;
  const altTag = imageSet ? imageSet.alt : alt;

  const imgAttributes = {
    srcSet: `${standard ? `${standard} 1.5x,` : ''} ${retina} 2x`,
    ...(phone ? { src: phone } : { src: retina }),
  };

  return (
    <img {...imgAttributes} alt={altTag} />
  );
};

const RetinaImage = (props) => {
  const {
    image, imageSet, alt, ...rest
  } = props;

  return (
    <div className="photo" {...rest}>
      <Image image={image} imageSet={imageSet} alt={alt} />
    </div>
  );
};

RetinaImage.defaultProps = { alt:'dragonchain' };

RetinaImage.prototype = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export { Image, RetinaImage };
