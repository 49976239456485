import React from 'react';
import PropTypes from 'prop-types';
import SubscribeForm from './Subscribe';

const SubscribeBar = (props) => {
  const {
    actionMessage, background, title, tags,
  } = props;
  return (
    <div className="subscribe-bar">
      <div className={`inner ${background}`}>
        <div className="subscribe-form-bar">
          <SubscribeForm tags={tags} actionMessage={actionMessage}>
            <div className="content">
              <p className="title">{title}</p>
            </div>
          </SubscribeForm>
        </div>
      </div>
    </div>
  );
};

SubscribeBar.defaultProps = {
  background: undefined,
};

SubscribeBar.propTypes = {
  title: PropTypes.string.isRequired,
  background: PropTypes.string,
  tags: PropTypes.string.isRequired, // comma delemited string
  actionMessage: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string.isRequired,
  ]).isRequired,
};

export default SubscribeBar;
