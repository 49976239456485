import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '../Controls';

const ListItem = (props) => {
  const {
    label, title, children, ...rest
  } = props;
  return (
    <div className="listItem" {...rest}>
      <Label name={label} />
      <h3 className="title">{title}</h3>
      <div className="description">
        {children}
      </div>
    </div>
  );
};

ListItem.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ListItem;
