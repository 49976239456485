import React from 'react';
import PropTypes from 'prop-types';
// import { LinkToButton } from '../Controls';

const Modal = ({
  children, show, closeCallback,
}) => (
  <div className="modal" style={{ display: show ? 'block' : 'none' }}>
    <div className="modal_overlay" onClick={closeCallback} /> {/* eslint-disable-line */}
    <div className="modal_box">
      {children}
      {/* <LinkToButton
        onClick={closeCallback}
        className="modal_close"
        color="default"
        hovercolor="default"
      >
        x
      </LinkToButton> */}
    </div>
  </div>
);

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool,
  closeCallback: PropTypes.func,
};

Modal.defaultProps = {
  show: false,
  closeCallback: () => (false),
};

export default Modal;
