import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ActionButtons from '../ActionButtons';
import { Block } from '../Containers';
import BlockLabel from '../BlockLabel';
import Title from '../Title';

const ContentFull = (props) => {
  const {
    blockLabel, blockLabelColor, title, titleColor, prismicId,
    titleSize, contentWidth, actions, children,
    background, textColor, ...rest
  } = props;

  const contentClasses = cx('contents-full', contentWidth);

  const renderActions = () => actions && (
    <ActionButtons actions={actions} />
  );

  const renderBlockLabel = () => blockLabel && (
    <BlockLabel color={blockLabelColor}>{blockLabel}</BlockLabel>
  );

  return (
    <Block id={prismicId} className={background}>
      <div className={contentClasses} {...rest}>
        {renderBlockLabel()}
        <div className="inner">
          <Title tag="h2" titleColor={titleColor} titleSize={titleSize}>
            {title}
          </Title>
          <div className={`description ${textColor}`}>
            {children}
          </div>
          {renderActions()}
        </div>
      </div>
    </Block>
  );
};

ContentFull.defaultProps = {
  background: undefined,
  contentWidth: undefined,
  blockLabel: undefined,
  blockLabelColor: 'blue',
  textColor: undefined,
  prismicId: '',
  actions: [],
};

ContentFull.propTypes = {
  blockLabel: PropTypes.string,
  blockLabelColor: PropTypes.oneOf(['default', 'white', 'blue-light', 'blue']),
  background: PropTypes.oneOf(['default', 'bg-blue-dark-bokeh']),
  title: PropTypes.string.isRequired,
  titleSize: PropTypes.oneOf(['default', 'large']).isRequired,
  titleColor: PropTypes.oneOf(['default', 'black', 'white', 'clouds-dark']).isRequired,
  textColor: PropTypes.oneOf(['default', 'white']),
  contentWidth: PropTypes.oneOf(['default', 'wide']),
  actions: PropTypes.arrayOf(
    PropTypes.shape(
      Object.assign(ActionButtons.propTypes, {}),
    ),
  ),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  prismicId: PropTypes.string,
};

export default ContentFull;
