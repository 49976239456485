import React from 'react';
import PropTypes from 'prop-types';
import { BackgroundImage, PrismicImage } from '../../utils';
import ActionButtons from '../ActionButtons';
import BlockLabel from '../BlockLabel';
import Title from '../Title';

const CallToAction = (props) => {
  const {
    title, titleColor, children, textColor, actions,
    photo, prismicId, imageSet,
    contentWidth, titleSize, blockLabel, blockLabelColor, ...rest
  } = props;

  const rbiHandlerKey = prismicId || photo;

  const renderActions = () => actions && (
    <ActionButtons actions={actions} />
  );

  const renderBlockLabel = () => blockLabel && (
    <BlockLabel color={blockLabelColor}>{blockLabel}</BlockLabel>
  );

  return (
    <div className={`call-to-action ${contentWidth} rbiHandler-${rbiHandlerKey}`} {...rest}>
      { prismicId
        ? PrismicImage(prismicId, imageSet)
        : BackgroundImage(photo)
      }
      <div className="inner">
        {renderBlockLabel()}
        <Title tag="div" titleColor={titleColor} titleSize={titleSize}>
          {title}
        </Title>
        <div className={`description ${textColor}`}>
          {children}
        </div>
        {renderActions()}
      </div>
    </div>
  );
};

CallToAction.defaultProps = {
  photo:'',
  prismicId: '',
  blockLabel: undefined,
  blockLabelColor: 'blue',
  imageSet: undefined,
};

CallToAction.propTypes = {
  blockLabel: PropTypes.string,
  blockLabelColor: PropTypes.oneOf(['default', 'white', 'blue-light', 'blue']),
  title: PropTypes.string.isRequired,
  titleSize: PropTypes.oneOf(['default', 'medium', 'large']).isRequired,
  titleColor: PropTypes.oneOf(['default', 'white', 'blue-light', 'clouds-light', 'clouds-dark']).isRequired,
  textColor: PropTypes.oneOf(['default', 'white', 'blue-light']).isRequired,
  contentWidth: PropTypes.oneOf(['default', 'medium', 'wide']).isRequired,
  photo: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape(
      Object.assign(ActionButtons.propTypes, {}),
    ),
  ).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,

  prismicId: PropTypes.string,
  imageSet: PropTypes.shape(
    Object.assign(PrismicImage.propTypes, {}),
  ),
};

export default CallToAction;
