import React, { Component } from 'react';
import { LinkTo } from '../../../components/Controls';
import dragonchainLinks from '../../../assets/data/links.dragonchain.json';
import socialLinks from '../../../assets/data/links.social.json';
import app from '../../../assets/data/app.json';

class Footer extends Component {
  renderLinkGroup = (rawLinkSet, group = false) => {
    const linkSet = [];
    let children = [];
    let i = 1;
    const n = rawLinkSet.length - 1;

    rawLinkSet.map((link, index) => {
      const {
        className, disabled, type, to, href, name,
      } = link;
      const classname = (disabled ? ' disabled' : '') + (className || '');

      children.push(
        <li key={name}>
          <LinkTo type={type} href={href} to={to} className={classname}>
            {name}
          </LinkTo>
        </li>,
      );
      if (group && (i === 1 || index === n)) {
        linkSet.push(<ul key={`set-${link.name}`}>{children}</ul>);
        children = [];
        i = 0;
      } else if (index === n) {
        linkSet.push(<ul key={`set-${link.name}`}>{children}</ul>);
      }
      i += 1;
      return '';
    });
    return linkSet;
  }

  render() {
    const year = (new Date().getFullYear());

    return (
      <footer className="footer">
        <div className="inner">
          <div className="promise">
            <div className="inner">
              <div className="jojo" />
              <div className="tagline">{app.tagline}</div>
              <div className="copyright">{`Copyright \u00A9 ${year} ${app.entity}, Inc. All rights reserved.`}</div>
              <div className="colors">
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
              </div>
            </div>
          </div>
          <div className="links">
            <div className="dragonchain-links">{this.renderLinkGroup(dragonchainLinks, true)}</div>
            <div className="social-links">{this.renderLinkGroup(socialLinks)}</div>
          </div>
          <div className="fineprint">
            <div className="disclaimer">
              <p>
                {app.disclaimer}
                {' '}
                <LinkTo type="route" to="/terms">Terms of use.</LinkTo>
              </p>
            </div>
            <div className="location">
              <div className="flag-usa" />
              <div className="country">U.S. Based</div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
