import React, { Component } from 'react';
import { LinkTo } from '../../../components/Controls';
import navigationLinkSetJson from '../../../assets/data/links.navigation.json';
import dragonchainLinkSetJson from '../../../assets/data/links.dragonchain.json';
import app from '../../../assets/data/app.json';

export default class Quicknav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      classes: 'quicknav',
    };
  }

  toggleQuicknav = () => {
    const { isOpen } = this.state;
    if (!isOpen) {
      this.setState({ classes: 'quicknav is-open', isOpen:true });
    } else {
      this.setState({ classes: 'quicknav', isOpen:false });
    }
  }

  renderDragonchainLinks = rawLinks => rawLinks.map(link => (
    link.subnav
      ? (''
    // link.subnav.navItems.map(subLink => (
    //   <li key={subLink.name}>
    //     <LinkTo type={subLink.type} href={subLink.href} to={subLink.to}>
    //       {subLink.name === 'Overview' ? 'Platform' : subLink.name}
    //     </LinkTo>
    //   </li>
    // ))
      )
      : (
        <li key={link.name}>
          <LinkTo type={link.type} href={link.href} to={link.to}>{link.name}</LinkTo>
        </li>
      )
  ))

  render() {
    const { classes } = this.state;
    const dragonchainLinkSet = dragonchainLinkSetJson.filter(link => link.quicknav);

    return (
      <nav
        role="presentation"
        className={classes}
        onClick={() => this.toggleQuicknav()}
      >
        <div className="handle red">
          <div className="controls"><div /></div>
          <div className="open-label">Dragonchain</div>
          <div className="close-label">Close Menu</div>
        </div>
        <div className="menu">
          <div className="inner">
            <div className="jojo"><div className="inner" /></div>
            <div className="links">
              <div className="link-list">
                <ul>
                  <li>{app.entity}</li>
                  {this.renderDragonchainLinks(navigationLinkSetJson)}
                </ul>
              </div>
              <div className="link-list">
                <ul>
                  <li>Ecosystem</li>
                  {this.renderDragonchainLinks(dragonchainLinkSet)}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}
