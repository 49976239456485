import React from 'react';
import RichText from '../../utils/Prismic/reactjs';
import { linkResolver } from '../../lib/globals';
import Hero from '../Hero';
import { Page } from '../Containers';
import JojoHero from '../JojoHero';
import ContentFull from '../ContentFull';
import CompetitorTable from '../CompetitorTable';
import CallToAction from '../CallToAction';
import Quote from '../Quote';
import MultiQuote from '../MultiQuote';
import Feature from '../Feature';
import FeatureHalves from '../FeatureHalves';
import { SubscribeBar, SubscribeLarge } from '../Subscribe';
import EngagementForm from '../EngagementForm';
import EngagementModal from '../EngagementModal';
import Video from '../Video';

const actionsHelper = items => items.map((item) => {
  const {
    button_color: color, button_hover_color: hovercolor,
    button_text: name, button_link: buttonLink,
  } = item;

  /*
    check if link is for an internal, no prismic page, and replace the link type and remove https://dragonchain.com

    EXAMPLE
      link_type = Web >>> route
      https://dragonchain.com/blog >>> /blog

  */
  const isInternalLink = buttonLink.link_type === 'Web' && buttonLink.url.indexOf('https://dragonchain.com') === 0;

  const type = isInternalLink ? 'route' : buttonLink.link_type.toLowerCase();

  const to = isInternalLink
    ? buttonLink.url.replace('https://dragonchain.com', '')
    : RichText.linkHelper(buttonLink, linkResolver);

  return {
    type, to, color, hovercolor, name,
  };
});

const componentBuilder = (Tag, key, options, items, text) => {
  const {
    title, title_size: titleSize, title_color: titleColor,
    text_color: textColor, image, size, content_width: contentWidth,
    block_label: blockLabel, block_label_color: blockLabelColor,
    button_text: buttonText, button_color: buttonColor, button_hover_color: buttonHoverColor,
    tags, author, background, image_size: imageSize, reverse,
    show_sdk_links: showSdkLinks, action_message: actionMessage, flag,
    formId, engagementName, show_subscribe_form: showSubscribeForm,
    background_image_position: backgroundImagePosition,
    poster, url,
  } = options;

  const paramaters = {
    prismicId: key,
  };

  if (author) { paramaters.author = author; }
  if (items) { paramaters.actions = actionsHelper(items); }
  if (textColor) { paramaters.textColor = textColor; }
  if (contentWidth) { paramaters.contentWidth = contentWidth; }
  if (image) { paramaters.imageSet = image; }
  if (Tag === Hero) { paramaters.isHeader = (size === 'small'); }
  if (titleSize) { paramaters.titleSize = titleSize; }
  if (title) { paramaters.title = title; }
  if (titleColor) { paramaters.titleColor = titleColor; }
  if (flag) { paramaters.flag = flag; }
  if (blockLabel) {
    paramaters.blockLabelColor = blockLabelColor; paramaters.blockLabel = blockLabel;
  }

  // Video
  if (poster) { paramaters.posterSet = poster; }
  if (url) { paramaters.url = url; }

  // Feature, Feature Halves
  if (background) { paramaters.background = background; }
  if (imageSize) { paramaters.imageSize = imageSize; }
  if (reverse) { paramaters.reverse = reverse; }

  // Subscribe, Subscribe Bar, Subscribe Large
  if (buttonColor) { paramaters.buttonColor = buttonColor; }
  if (buttonHoverColor) { paramaters.hoverColor = buttonHoverColor; }
  if (buttonText) { paramaters.buttonText = buttonText; }
  if (actionMessage) { paramaters.actionMessage = RichText.render(actionMessage, linkResolver); }
  if (tags) { paramaters.tags = tags; }
  if (showSdkLinks) { paramaters.showSdkLinks = showSdkLinks; }
  if (showSubscribeForm) { paramaters.showSubscribeForm = showSubscribeForm; }

  // Engement
  if (formId) { paramaters.formId = formId; }
  if (engagementName) { paramaters.engagementName = engagementName; }

  // Hero
  if (backgroundImagePosition) { paramaters.backgroundImagePosition = backgroundImagePosition; }

  return (
    <Tag key={paramaters.prismicId} {...paramaters}>
      {text}
    </Tag>
  );
};

const featureHalves = (Tag, key, options, items) => {
  const {
    block_label: blockLabel, block_label_color: blockLabelColor,
    reverse, image,
  } = options;

  const paramaters = { prismicId: key };

  if (reverse) { paramaters.reverse = reverse; }
  if (image) { paramaters.imageSet = image; }
  if (items) { paramaters.listItemSet = items; }
  if (blockLabel) {
    paramaters.blockLabelColor = blockLabelColor; paramaters.blockLabel = blockLabel;
  }

  return (
    <Tag key={paramaters.prismicId} {...paramaters} />
  );
};

const serializeTag = (Tag, key, text) => (<Tag key={key}>{text}</Tag>);

const returnTag = (type) => {
  if (type === 'JojoHero') return JojoHero;
  if (type === 'Hero') return Hero;
  if (type === 'ContentFull') return ContentFull;
  if (type === 'CompetitorTable') return CompetitorTable;
  if (type === 'CallToAction') return CallToAction;
  if (type === 'Quote') return Quote;
  if (type === 'MultiQuote') return MultiQuote;
  if (type === 'Feature') return Feature;
  if (type === 'FeatureHalves') return FeatureHalves;
  if (type === 'SubscribeLarge') return SubscribeLarge;
  if (type === 'SubscribeBar') return SubscribeBar;
  if (type === 'EngagementForm') return EngagementForm;
  if (type === 'EngagementModal') return EngagementModal;
  if (type === 'Video') return Video;
  return Page;
};

const SerializeSlice = document => document.data.body.map((slice, index) => {
  const { slice_type: type, primary, items } = slice;
  const key = `${type}-${index}`;
  const Tag = type === 'Engagement' ? returnTag(`${type}${primary.type}`) : returnTag(type);

  switch (type) {
    case 'Hero': case 'JojoHero': case 'Quote': case 'CallToAction':
    case 'ContentFull': case 'Feature':
    case 'SubscribeLarge': case 'SubscribeBar': case 'Engagement':
    case 'Video': {
      const text = primary.text ? RichText.render(primary.text, linkResolver) : '';
      return componentBuilder(Tag, key, primary, items, text);
    }
    case 'FeatureHalves': { return featureHalves(Tag, key, primary, items); }
    case 'MultiQuote': { return <Tag key={key} items={items} />; }
    case 'Page': case 'CompetitorTable': {
      const text = primary.text && (
        <section>
          {RichText.render(primary.text, linkResolver)}
        </section>
      );
      return serializeTag(Tag, key, text);
    }
    default: return null;
  }
});

export default SerializeSlice;
