import React from 'react';
import PropTypes from 'prop-types';
import ActionButtons from '../ActionButtons';
import { PrismicImage, BackgroundImage } from '../../utils';
import Title from '../Title';

const JojoHero = (props) => {
  const {
    title, titleSize, photo, actions, children, titleColor,
    textColor, prismicId, imageSet, ...rest
  } = props;

  const rbiHandlerKey = prismicId || photo;

  const renderActions = () => actions && (
    <ActionButtons actions={actions} />
  );

  return (
    <div className={`hero-jojo rbiHandler-${rbiHandlerKey}`} {...rest}>
      { prismicId
        ? (imageSet && PrismicImage(prismicId, imageSet))
        : (photo && BackgroundImage(photo))
      }
      <div className="inner">
        <div className="jojo"><div className="inner" /></div>
        <div className="content">
          <div className="inner">
            <Title tag="h1" titleColor={titleColor} titleSize={titleSize}>
              {title}
            </Title>
            <div className={`description ${textColor}`}>
              {children}
            </div>
            {renderActions()}
          </div>
        </div>
      </div>
    </div>
  );
};

JojoHero.defaultProps = {
  titleSize:'',
  photo:'',
  actions: [],
  prismicId: '',
  imageSet: undefined,
};

JojoHero.propTypes = {
  title: PropTypes.string.isRequired,
  titleSize: PropTypes.oneOf(['medium', 'standard', 'small', '']),
  titleColor: PropTypes.string.isRequired,
  textColor: PropTypes.oneOf(['white', 'grey', 'black', 'blue-light']).isRequired,
  photo: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape(
      Object.assign(ActionButtons.propTypes, {}),
    ),
  ),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,

  prismicId: PropTypes.string,
  imageSet: PropTypes.shape(
    Object.assign(PrismicImage.propTypes, {}),
  ),
};

export default JojoHero;
