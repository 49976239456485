import React from 'react';
import PropTypes from 'prop-types';
import ActionButtons from '../../ActionButtons/ActionButtons';

const PageActions = (props) => {
  const { actions, children, ...rest } = props;

  const renderActions = () => actions && (
    <ActionButtons actions={actions} />
  );

  return (
    <div className="page_actions" {...rest}>
      {renderActions()}
      {children}
    </div>
  );
};

PageActions.defaultProps = {
  children: undefined,
};

PageActions.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  actions: PropTypes.arrayOf(
    PropTypes.shape(
      Object.assign(ActionButtons.propTypes, {}),
    ),
  ).isRequired,
};

export default PageActions;
