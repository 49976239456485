import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { BackgroundImage, PrismicImage } from '../../utils';

class VideoPlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playing: false,
      played: false,
    };
  }

  play = () => {
    const { played } = this.state;

    if (!played) {
      this.setState({ playing: true });
      this.setState({ played: true });
    }
  }

  ref = (player) => { this.player = player; }

  render() {
    const { playing, played } = this.state;
    const {
      url, prismicId, poster, posterSet,
    } = this.props;

    return (
      <div className={played ? 'video-player js-played' : 'video-player'} role="presentation" onClick={this.play}>
        <div className="inner">
          <div className="video-play-button"><div className="video-play-button-icon"><div className="video-play-button-icon-triangle" /></div></div>
          { prismicId
            ? PrismicImage(prismicId, posterSet)
            : BackgroundImage(poster)
          }
          <div className={`video-poster rbiHandler-${prismicId || poster}`} />
          <div className="video-holder">
            <ReactPlayer
              ref={this.ref}
              url={url}
              controls
              playing={playing}
            />
          </div>
        </div>
      </div>
    );
  }
}

VideoPlayer.defaultProps = {
  poster: '',
  prismicId: '',
  posterSet: undefined,
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  poster: PropTypes.string,
  prismicId: PropTypes.string,
  posterSet: PropTypes.shape(
    Object.assign(PrismicImage.propTypes, {}),
  ),
};

export default VideoPlayer;
