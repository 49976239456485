import React from 'react';
import PropTypes from 'prop-types';
import BlockLabel from '../BlockLabel';
import { Image } from '../RetinaImage/RetinaImage';

const Quote = (props) => {
  const {
    blockLabel, blockLabelColor, author, children,
    prismicId, image, imageSet, ...rest
  } = props;

  const renderBlockLabel = () => blockLabel && (
    <BlockLabel color={blockLabelColor}>
      {blockLabel}
    </BlockLabel>
  );

  const logo = () => (
    <div className="logo">
      <Image image={image} imageSet={imageSet} />
    </div>
  );

  return (
    <div className="quote" {...rest}>
      <div className="inner">
        {renderBlockLabel()}
        <div className="layout">
          {logo()}
          <div className="content">
            <blockquote>
              {children}
              <footer>{author}</footer>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  );
};

Quote.defaultProps = {
  blockLabel: undefined,
  blockLabelColor: 'default',
  prismicId: '',
  image: undefined,
  imageSet: undefined,
};

Quote.propTypes = {
  prismicId: PropTypes.string,
  blockLabel: PropTypes.string,
  blockLabelColor: PropTypes.oneOf(['default', 'white', 'blue-light', 'blue', 'grey']),
  author: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  image: PropTypes.string,
  imageSet: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string,
    standard: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

export default Quote;
