import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Icon } from '..';

// Colors: white, grey, blue, blue_light, yellow
// Hover Colors: blue_dark, blue_med

const Button = ({
  isRoute,
  isAnchor,
  route,
  onClick,
  disabled,
  children,
  color,
  hover,
  icon,
  style,
  small,
  info,
  ...rest
}) => {
  const classes = cx(
    style,
    color,
    {
      button: !info,
      button_and_info: info,
      small,
      disabled,
      [`hover_${hover}`]: hover,
      has_icon_check: icon,
    },
  );

  const disable = (!!disabled);

  // style issue for icon
  if (isAnchor) {
    return (
      <a href={route} disabled={disable} className={classes} {...rest}>
        <div className="button_status">
          {icon && (<Icon name={icon} color="white" button />)}
          {children}
        </div>
        {info && <div className="button_info">{info}</div>}
      </a>
    );
  }

  // style issue for icon
  if (isRoute) {
    return (
      <Link to={route} disabled={disable} className={`button ${classes}`} {...rest}>
        <div className="button_status">
          {icon && (<Icon name={icon} color="white" button />)}
          {children}
        </div>
        {info && <div className="button_info">{info}</div>}
      </Link>
    );
  }

  return (
    <button type="button" disabled={disable} onClick={onClick} className={classes} {...rest}>
      <div className="button_status">
        {icon && (<Icon name={icon} color="white" button />)}
        {children}
      </div>
      {info && <div className="button_info">{info}</div>}
    </button>
  );
};

Button.defaultProps = {
  children: '',
  disabled: false,
  onClick: null,
  hover: '',
  route: '',
  isRoute: false,
  isAnchor: false,
  small:false,
  icon:false,
  color:'',
  style:'',
  info:'',
};

export default Button;
