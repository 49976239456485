import React, { Component } from 'react';
import history from '../../history';
import { AppStateContext } from '../../contexts';

class RequestDemo extends Component {
  toggleRequestDemoModal = (changeAppState) => {
    changeAppState('request-demo', false);
    history.push({ search: '' });
  }

  render() {
    return (
      <AppStateContext.Consumer>
        {(context) => {
          const { changeAppState, appState } = context;
          if (appState['request-demo']) {
            window.open('https://meetings.hubspot.com/dragonchain/schedule-a-demo', '_blank');
            this.toggleRequestDemoModal(changeAppState);
          }
        }}
      </AppStateContext.Consumer>
    );
  }
}
export default RequestDemo;
