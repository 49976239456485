import React, { Component } from 'react';
import ReactSVG from 'react-inlinesvg';
import { LinkTo, LinkToButton } from '../../../components/Controls';
import navigationLinkSetJson from '../../../assets/data/links.navigation.json';

import labsIcon from '../../../assets/images/icon/subnav-labs.svg';
import developerIcon from '../../../assets/images/icon/subnav-developer.svg';
import enterpriseIcon from '../../../assets/images/icon/subnav-enterprise.svg';
import subnavGithubIcon from '../../../assets/images/icon/subnav-github.svg';

import { Nav, NavItem } from '../../../components/Nav';
import Sdks from '../../../components/Sdks';

export default class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path:'',
    };
  }

  componentDidUpdate = () => {
    const { path } = this.state;
    const currentPath = window.location.pathname.split('/')[1];
    if (path !== currentPath) {
      this.setState({ path:currentPath });
      this.forceUpdate();
    }
  }

  clickNav = (e) => {
    const li = e.target.closest('li');
    li.addClass('js-subnav-closed');
  }

  mouseOutNav = (e) => {
    const li = e.target.closest('li');

    setTimeout(() => {
      li.removeClass('js-subnav-closed');
    }, 200);
  }

  clickSubnav = (e) => {
    const li = e.target.closest('.subnav-links, .subnav-content').parentNode.closest('li');
    li.addClass('js-subnav-closed');
    setTimeout(() => {
      li.removeClass('js-subnav-closed');
    }, 250);
  }

  developerPanel = showPanel => showPanel && (
    <div className="subnav-content">
      <div className="inner">
        <div className="title-icon">
          <ReactSVG src={subnavGithubIcon} />
        </div>
        <div className="title">Dragonchain SDK</div>
        <div className="content">
          Work with your Dragonchain API using official Dragonchain
          SDKs, now available for Node.js and Python.
        </div>
        <Sdks />
      </div>
    </div>
  );

  getIcon = (icon) => {
    const iconSvg = { labs: labsIcon, developer: developerIcon, enterprise: enterpriseIcon }[icon];

    return (
      <div className="icon">
        <ReactSVG src={iconSvg} />
      </div>
    );
  }

  getSubNavItemSet = navItems => navItems.map(item => (
    <LinkTo
      key={item.name}
      type={item.type}
      to={item.to}
      href={item.href}
      onClick={this.clickSubnav}
    >
      <div className="inner">
        {item.icon && this.getIcon(item.icon)}
        <div className="content">
          <div className="title">{item.name}</div>
          {item.description && <p>{item.description}</p>}
        </div>
      </div>
    </LinkTo>
  ));

  getSubnav = (parent, subnav) => {
    const { isMega, developerPanel, navItems } = subnav;
    const subNavClasses = `subnav ${isMega === true ? 'is-mega-menu' : ''}`;
    return (
      <div key={parent} className={subNavClasses}>
        <div className="inner">
          <div className="layout">
            <Nav className="subnav-links">
              <NavItem>
                {this.getSubNavItemSet(navItems)}
              </NavItem>
            </Nav>
            {this.developerPanel(developerPanel)}
          </div>
        </div>
      </div>
    );
  }

  getLinks = () => navigationLinkSetJson.map(link => (
    <NavItem key={link.name}>
      <LinkTo type={link.type} to={link.to} href={link.href}>{link.name}</LinkTo>
      {link.subnav && this.getSubnav(link.name, link.subnav)}
    </NavItem>
  ))

  render() {
    const { path } = this.state;
    return (
      <Nav className="nav">
        {this.getLinks(path)}
        <li className="has-button">
          <LinkToButton size="small" type="internal" color="blue" hovercolor="red" href="https://console.dragonchain.com">Managed Services</LinkToButton>
        </li>
      </Nav>
    );
  }
}
