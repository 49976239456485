import React from 'react';
import PropTypes from 'prop-types';
import { BackgroundImage, PrismicImage } from '../../utils';
import ActionButtons from '../ActionButtons';
import Title from '../Title';

const Hero = (props) => {
  const {
    isHeader, photo, title, titleColor, titleSize, children, textColor,
    actions, prismicId, imageSet, backgroundImagePosition, ...rest
  } = props;

  const rbiHandlerKey = prismicId || photo;

  const renderActions = () => actions.length > 0 && (
    <ActionButtons actions={actions} />
  );

  const renderDescription = () => children !== undefined && (
    <div className={`description ${textColor}`}>
      {children}
    </div>
  );
  return (
    <div className={`hero rbiHandler-${rbiHandlerKey}  ${isHeader ? 'is-header' : ''} ${backgroundImagePosition || ''}`}>
      { prismicId
        ? PrismicImage(prismicId, imageSet)
        : BackgroundImage(photo)
      }
      <div className="inner" {...rest}>
        <div className="content">
          <div className="inner">
            <Title tag="h1" titleColor={titleColor} titleSize={titleSize}>
              {title}
            </Title>
            {renderDescription()}
            {renderActions()}
          </div>
        </div>
      </div>
    </div>
  );
};

Hero.defaultProps = {
  isHeader: false,
  title: '',
  photo:'',
  titleColor: '',
  textColor: '',
  titleSize: '',
  actions: [],
  children: undefined,
  prismicId: '',
  imageSet: undefined,
  backgroundImagePosition: 'default',
};

Hero.propTypes = {
  isHeader: PropTypes.bool,
  photo: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.oneOf(['default', 'white', 'blue-light', 'clouds-light', 'clouds-dark', '']),
  titleSize: PropTypes.oneOf(['default', 'large', 'medium', 'small', '']),
  textColor: PropTypes.oneOf(['default', 'white', 'grey', 'black', 'blue-light', '']),
  backgroundImagePosition: PropTypes.oneOf(
    [
      'default',
      'center-center',
      'center-top',
      'center-bottom',
      'left-top',
      'left-center',
      'left-bottom',
      'right-top',
      'right-center',
      'right-bottom',
    ],
  ),
  actions: PropTypes.arrayOf(
    PropTypes.shape(
      Object.assign(ActionButtons.propTypes, {}),
    ),
  ),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),

  prismicId: PropTypes.string,
  imageSet: PropTypes.shape(
    Object.assign(PrismicImage.propTypes, {}),
  ),
};

export default Hero;
