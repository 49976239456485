import React, { Component } from 'react';
import {
  Route, Router, Switch,
} from 'react-router-dom';
import history from './history';
import { AnalyticListener, ScrollToTop } from './utils';
import { AppStateContext } from './contexts';
import Main from './layout';
import { DragonchainConfig } from './lib/globals';

const defaultState = {
  initialized: true,
  setChangeAppStates: false,
  path: '',
  theme: 'light',
};

export default class App extends Component {
  constructor() {
    super();
    this.state = defaultState;
  }

  resetAppState = () => {
    this.setState(defaultState);
    this.changeAppState('initialized', true);
  }

  changeAppState = (key, value) => { this.setState({ [key]: value }); }

  render() {
    const { state, changeAppState } = this;
    return (
      <AppStateContext.Provider value={{ appState: state, changeAppState }}>
        <Router history={history}>
          <AnalyticListener
            tagManagerId={DragonchainConfig.GTM}
            googleAnalyticId={DragonchainConfig.GA_TAG}
          >
            <ScrollToTop>
              <Switch>
                <Route path="/" component={Main} />
                <Route component={Main} />
              </Switch>
            </ScrollToTop>
          </AnalyticListener>
        </Router>
      </AppStateContext.Provider>
    );
  }
}
