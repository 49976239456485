import React, { Component } from 'react';
import queryString from 'query-string';

export default (Wrapped, NotFound, Loading) => class PrismicPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      document: null,
      isLoaded: false,
      currentUid: null,
      params: null,
      engage: '',
    };
  }

  componentDidMount = async () => {
    const {
      match: { params },
      location: { search },
      uid,
      changeAppState,
    } = this.props;
    const newUid = uid || params.uid;
    this.setState({ params, currentUid: newUid });

    await this.getByUID(newUid);
    this.showEngagement(search, changeAppState);
  }

  componentWillReceiveProps = async (props) => {
    const { currentUid } = this.state;
    const {
      location: { search },
      match: { params },
      uid,
      changeAppState,
    } = props;
    this.showEngagement(search, changeAppState);
    const newUid = uid || params.uid;
    if (currentUid === newUid || currentUid === null) { return; }
    await this.setState({ params, currentUid: newUid });
    await this.getByUID(newUid);
  }

  showEngagement = (search, changeAppState) => {
    const { engage } = this.state;
    const query = queryString.parse(search);
    if (query.engage !== '' && query.engage !== engage) {
      this.setState({ engage: query.engage });
      changeAppState(`${query.engage}`, true);
    }
  }

  getByUID = async (uid) => {
    const { refreshPrismic } = this.props;
    await refreshPrismic();
    try {
      const { prismic } = this.props;
      const document = await prismic.api.getByUID(Wrapped.customType, uid);
      this.setState({ document, isLoaded: true });
    } catch (error) {
      if (process.env.REACT_APP_STAGE !== 'production') { console.log(error); } // eslint-disable-line
    }
  }

  render() {
    const { document, isLoaded, params } = this.state;
    const { changeAppState, location } = this.props;
    return (
      isLoaded // eslint-disable-line
        ? document // eslint-disable-line
          ? (
            <Wrapped
              document={document}
              params={params}
              changeAppState={changeAppState}
              location={location}
            />
          )
          : window.location.replace('/') // if not found, redirect to landing
        : (Loading ? (<Loading />) : null)
    );
  }
};
