import React from 'react';
import { Helmet } from 'react-helmet';

const PrismicPreviewConfig = ({ endpoint }) => process.env.REACT_APP_STAGE !== 'production' && (
  <Helmet
    script={[
      { type: 'text/javascript', innerHTML: `window.prismic = { endpoint: '${endpoint}' }` },
      { type: 'text/javascript', src: '//static.cdn.prismic.io/prismic.min.js' },
    ]}
  />
);

export default PrismicPreviewConfig;
