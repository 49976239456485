import React from 'react';
import PropTypes from 'prop-types';

const BlockLabel = (props) => {
  const { color, children, ...rest } = props;

  return (
    <div className={`block-label ${color}`} {...rest}>
      <div className="inner">{children}</div>
    </div>
  );
};

BlockLabel.defaultProps = {
  color: 'default',
};

BlockLabel.propTypes = {
  color: PropTypes.oneOf(['default', 'white', 'blue-light', 'blue']),
  children: PropTypes.string.isRequired,
};

export default BlockLabel;
