import { Errors } from './globals';
import { UNAUTHORIZED } from './globals/errors';

const { NOT_FOUND, GENERIC_ERROR, BAD_REQUEST } = Errors;

class HttpRequestApi {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.defaultOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
  }

  get = async (path) => {
    const options = { method: 'GET' };
    return this.makeRequest(path, options);
  }

  post = async (path, body) => {
    const options = { method: 'POST', body: JSON.stringify(body) };
    return this.makeRequest(path, options);
  }

  makeRequest = async (path, options) => {
    const requestParams = { ...this.defaultOptions, ...options };
    try {
      const result = await fetch(`${this.baseUrl}${path}`, requestParams);
      if (result.status === 400) { throw new BAD_REQUEST(); }
      if (result.status === 404) { throw new NOT_FOUND(); }
      if (result.status === 403) { throw new UNAUTHORIZED(); }
      if (result.status === 500) { throw new GENERIC_ERROR(); }

      return result.json();
    } catch (error) { return { ok:false }; }
  }
}

export default HttpRequestApi;
