import React, { Component } from 'react';
import { PrismicPage } from '../../utils/Prismic';
import { externalLink } from '../../lib/globals';
import NotFoundCard from '../../components/NotFoundCard';
import LoadingCard from '../../components/LoadingCard';
import { PageMeta } from '../../components/Meta';
import SerializeSlice from '../../components/SerializeSlice';
import RequestDemo from '../../components/RequestDemo';

class PageBuilder extends Component {
  static customType = 'page_builder';

  async componentDidMount() {
    const { changeAppState, document } = this.props;
    const theme = document ? document.data.theme : 'light';
    changeAppState('theme', theme);
    // this.addMeetingsScript();
  }

  // removed for speed. replaced with popup
  // addMeetingsScript = () => {
  //   const script = document.createElement('script');
  //   script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
  //   document.body.appendChild(script);
  // }

  render() {
    const { document } = this.props;
    return (
      document && (
      <main>
        <RequestDemo />
        <PageMeta
          pageUrl={externalLink(document)}
          title={document.data.seo_title}
          description={document.data.seo_description}
          cardSet={document.data.body1} // SEO cards are in body1
          isCrawlable
        />
        {SerializeSlice(document)}
      </main>
      )
    );
  }
}

const NotFound = () => <NotFoundCard theme="light" />;
const Loading = () => <LoadingCard theme="light" />;

export default PrismicPage(
  PageBuilder,
  NotFound,
  Loading,
);
