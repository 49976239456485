import React from 'react';
import PropTypes from 'prop-types';

const Spinner = ({ color }) => (
  <div className={`smooth-spinner ${color}`}>
    <svg viewBox="0 0 50 50">
      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
    </svg>
  </div>
);

Spinner.defaultProps = {
  color: 'default',
};

Spinner.propTypes = {
  color: PropTypes.oneOf(
    [
      'default',
      'blue',
      'blue_black',
      'blue_dark',
      'blue_light',
      'blue_med',
      'grey',
      'grey_light',
      'grey_med',
      'red',
    ],
  ),
};

export default Spinner;
