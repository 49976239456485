import HttpRequestApi from './HttpRequestApi';
import { Errors, DragonchainConfig } from './globals';

const { INVALID_INPUT } = Errors;
const { SUBSCRIBE_API } = DragonchainConfig;
export default class EternalApiWrapper {
  constructor() {
    this.api = new HttpRequestApi(SUBSCRIBE_API);
  }

  createMember = async (email, mergeFields, tags = 'dragonchain') => {
    try {
      if (email.length === 0) { throw new INVALID_INPUT('Email is missing.'); }
      const body = {
        email,
        mergeFields,
        tags,
      };
      const call = await this.api.post('/members', body);
      const response = !!call.ok;
      return response;
    } catch (e) { return 'Something went wrong. Please try again.'; }
  }
}
