import React from 'react';
import ReactSVG from 'react-inlinesvg';
import { LinkTo } from '../Controls';
import { AnalyticAction } from '../../utils';
import subnavNodejsIcon from '../../assets/images/icon/subnav-nodejs.svg';
import subnavPythonIcon from '../../assets/images/icon/subnav-python.svg';

const trackAction = (type) => {
  AnalyticAction.track(`Opened ${type} SDK`, { $sdk: 'type' });
};

const Sdks = () => (
  <div className="sdks">
    <div className="sdk">
      <LinkTo
        type="blank"
        href="https://www.npmjs.com/package/dragonchain-sdk"
        onClick={() => trackAction('NodeJS')}
      >
        <ReactSVG src={subnavNodejsIcon} />
      </LinkTo>
    </div>
    <div className="sdk">
      <LinkTo
        type="blank"
        href="https://pypi.org/project/dragonchain-sdk"
        onClick={() => trackAction('Python')}
      >
        <ReactSVG src={subnavPythonIcon} />
      </LinkTo>
    </div>
  </div>
);

export default Sdks;
